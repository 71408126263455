<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4 mb-3">
                        <div class="getStart bg-white boxed box-shadow guide_lines" id="getstarted">
                            <div class="repeatList">
                                <a @click.prevent="ZuluTradeTabs = 'About'" :class="(ZuluTradeTabs == 'About')?'active':''" class="js-anchor-link d-flex" href="#About">{{$t('help.text13')}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="bg-white boxed box-shadow p-md-4">
                            <!-- Main item -->
                            <section class="inner-box fullList" id="About">
                                <h3 class="border-bottom pb-3 mb-3">{{$t('help.text36')}}</h3>
                                <!-- Accordion item -->
                                <div class="selected support_sec">
                                    <h4>{{$t('help.text14')}}</h4>
                                    <p>{{$t('help.text15')}}</p>
                                    <p>{{$t('help.text16')}}</p>
                                    <p>{{$t('help.text17')}}</p>

                                    <h4>{{$t('help.text18')}}</h4>
                                    <p>{{$t('help.text19')}}</p>
                                    <ul class="dotList">
                                        <li>{{$t('help.text20')}}</li>
                                        <li>{{$t('help.text21')}}</li>
                                    </ul>

                                    <h4>{{$t('help.text22')}}</h4>
                                    <p>{{$t('help.text23')}}</p>
                                    <ul class="dotList">
                                        <li>{{$t('help.text24')}} <a class="secondary" href="mailto:sales@zulturade.com">{{$t('help.text37')}}</a>{{$t('help.text25')}}  <a class="secondary" href="mailto:partnerships@zulturade.com">{{$t('help.text26')}} .</a></li>
                                        <li>T{{$t('help.text27')}} <a class="secondary" href="mailto:tradersdesk@zulturade.com">{{$t('help.text28')}}.</a></li>
                                        <li>{{$t('help.text29')}} <a class="secondary" href="mailto:affiliates@zulturade.com">{{$t('help.text30')}}</a></li>
                                        <li>{{$t('help.text31')}}<a class="secondary" href="mailto:qualitycontrol@zulturade.com">{{$t('help.text32')}}.</a></li>
                                        <li>{{$t('help.text33')}} <router-link class="secondary" to= "/help-center">{{$t('help.text34')}}  </router-link> {{$t('help.text35')}}.</li>
                                    </ul>
                                    
                                </div>
                            </section>
                            <!-- Main item -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
 
</template>
<script>
export default {
    setup() {

    },
    data() {
        return {
            ZuluTradeTabs : 'About'
        };
    },
}
</script>